<template>
  <ul class="tab_01">
    <li v-for="item in list" :key="item.id" :class="{ on: selectedItem?.id === item.id }">
      <a href="#" @click.prevent="select(item)">{{ item.title }}</a>
    </li>
  </ul>
</template>

<script setup lang="ts">
interface ITab {
  readonly id: string
  readonly title: string
}

interface ClickBeforeAction {
  (param: ITab): Promise<Boolean>;
}

const props = defineProps<{ modelValue: string, list: ITab[], clickBefore?: ClickBeforeAction }>()

const emit = defineEmits<{(e: 'update:modelValue', id: string): void, (e: 'select', id: string): void, (e: 'fail'): void}>()

const selectedItem = ref<ITab | undefined>()

onMounted(() => init())

watch(() => props.modelValue, () => {
  init()
})

const init = () => {
  selectedItem.value = props.list.find((item: ITab) => item.id === props.modelValue)
}

const select = async (tab: ITab) => {
  if (props.clickBefore) {
    const isClickBefore = await props.clickBefore(tab)
    if (!isClickBefore) {
      emit('fail')
      return
    }
  }
  selectedItem.value = tab

  emit('update:modelValue', tab.id)
  emit('select', tab.id)
}

</script>

<style scoped>
.tab_01{position:relative; display:flex; gap:24px;}
.tab_01:before{content:''; display:block; position: absolute; left:0; bottom:0; height:1px; width:100%; background:var(--color-line);}
.tab_01 li a{display:block; padding:0 8px 12px; box-sizing: border-box; color:var(--color-text-default); font-size: var(--font-size-6); line-height: 1.25; text-align:center;}
.tab_01 li.on a{color: var(--color-text-title); border-bottom:3px solid var(--color-sub1); padding-bottom:9px; font-weight:700;}
</style>
